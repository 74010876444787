<template>
  <div class="production-report main-panel large">
    <h3 class="panel-title">生产报表</h3>
    <div class="top">
      <el-form inline>
        <el-row>
          <el-form-item label="日期">
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                size="mini"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期" />
          </el-form-item>
          <el-form-item label="工厂">
            <el-select size="mini" clearable v-model="department" placeholder="工厂" style="width: 140px;" value-key="id">
              <el-option v-for="department in departments" :key="department.id" :label="department.name" :value="department" />
            </el-select>
          <el-form-item label="下单属性">
            <el-select size="mini" clearable v-model="type" placeholder="下单属性" style="width: 140px;">
              <el-option label="日常" :value="1" />
              <el-option label="订单" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否开卡">
            <el-select size="mini" clearable v-model="isOpenCard" placeholder="是否开卡" style="width: 140px;">
              <el-option label="已开卡" :value="0" />
              <el-option label="未开卡" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="进度" v-if="isOpenCard !== 1">
            <el-select size="mini" clearable v-model="progressId" placeholder="进度" style="width: 140px;">
              <el-option label="开卡" :value="0" />
              <el-option label="翻布" :value="1" />
              <el-option label="进仓" :value="2" />
            </el-select>
          </el-form-item>
          </el-form-item>
        </el-row>
        <el-form-item label="卡号">
          <el-input v-model="form.cardNo" placeholder="开卡卡号" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="品名">
          <el-select size="mini" filterable clearable v-model="product" placeholder="请选择产品" value-key="id">
            <el-option v-for="item in products" :key="item.id" :label="item.pname" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="色号">
          <el-select size="mini" filterable clearable v-model="color" placeholder="请选择色号" value-key="id">
            <el-option v-for="item in colors" :key="item.id" :label="item.colornumber" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" icon="el-icon-search" size="mini" @click="search" style="margin-left: 10px;">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" height="500" v-loading="isLoading">
<!--      <el-table-column align="center" label="生产日期" min-width="110">
        <template #default="scope">{{ $day(scope.row.date).format("YYYY-MM-DD") }}</template>
      </el-table-column>-->
      <el-table-column align="center" label="工厂名" prop="departmentName"></el-table-column>
      <el-table-column v-if="['管理员', '生产跟单员'].includes(position)" align="center" label="开卡卡号" prop="cardNo"></el-table-column>
      <el-table-column align="center" label="品名" prop="productName"></el-table-column>
      <el-table-column align="center" label="色号" prop="colornumber" min-width="80"></el-table-column>
      <el-table-column align="center" label="进度" min-width="100">
        <template #default="scope">{{ scope.row.cardNo ? scope.row.lastProgress : "未开卡" }}</template>
      </el-table-column>
      <el-table-column align="center" label="下单属性" width="80">
        <template #default="scope">{{ ['', '日常', '订单'][scope.row.type] }}</template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="content" min-width="80"></el-table-column>
      <el-table-column v-if="['管理员', '生产跟单员'].includes(position)" align="center" label="状态" width="150">
        <template #default="scope">
          <template v-if="scope.row.status === 0"><span style="color: #aaa; font-size: 14px; user-select: none;">已取消</span></template>
          <template v-else>
            <el-dropdown v-if="![2,3,4].includes(scope.row.status)" @command="command => updateStatus(scope.row.progressId, command, scope.row.status)">
              <el-button :type="[2,3,4].includes(scope.row.status) ? 'danger' : 'info'" size="mini">加急</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="2">普急</el-dropdown-item>
                  <el-dropdown-item :command="3">中急</el-dropdown-item>
                  <el-dropdown-item :command="4">特急</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button v-else :type="['','','success','warning','danger'][scope.row.status]" size="mini" @click="updateStatus(scope.row.progressId, 1, scope.row.status)">{{ ['','','普急','中急','特急'][scope.row.status] }}</el-button>

            <el-popconfirm title="确认取消吗?"
                           confirmButtonText='确认'
                           cancelButtonText='取消'
                           @confirm="updateStatus(scope.row.progressId, 0, scope.row.status)">
              <template #reference>
                <el-button :type="scope.row.status === 0 ? 'danger' : 'info'" size="mini" style="margin-left: 10px;">取消</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>

      <template #append>
        <div class="load-more"
             :class="{'no-more': noMore}"
             v-if="list.length"
             @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}</div>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateRange: null,
      department: {},
      product: {},
      color: {},
      progressId: null,
      type: null,
      isOpenCard: null,

      form: {
        cardNo: "",
        currentPage: 1,
        pageSize: 50
      },

      position: "",
      list: [],
      noMore: false,
      isLoading: false,

      departments: [],
      products: []
    }
  },

  computed: {
    colors() {
      if (!this.product.id) return [];
      return this.product.colorcards;
    }
  },

  watch: {
    "form.product": {
      handler: async function () {
        this.color = {}
        await this.$nextTick()
      },
      deep: true
    }
  },

  async mounted() {
    this.departments = await this.$getList.getDepartments()
    this.products = await this.$getList.getProducts()
    if (this.$store.state.isGd) {
      this.department = this.departments.find(department => department.id === this.$store.state.departmentId)
    }
    await this.search()
  },

  methods: {
    async search() {
      this.form.currentPage = 1
      this.noMore = false
      this.list = []
      await this.getList()
    },

    async getList() {
      if (this.noMore) return false;
      this.isLoading = true

      const sendData = {...this.form, cardNoCondition: [0, 1].includes(this.isOpenCard) ? this.isOpenCard : 2}
      !sendData.cardNo && Reflect.deleteProperty(sendData, "cardNo")
      this.dateRange && (sendData.startDate = this.dateRange[0])
      this.dateRange && (sendData.endDate = this.dateRange[1])
      this.department.id && (sendData.departmentId = this.department.id)
      this.product.id && (sendData.productId = this.product.id)
      this.color.id && (sendData.colorcardId = this.color.id)
      this.progressId !== null && (sendData.progressCondition = this.progressId)
      this.type && (sendData.type = this.type)

      const res = await this.$api.Report.ProductionReport.getList(sendData).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.form.currentPage += 1
        this.list.push(...res.data.data)
        if (!this.position) {
          this.position = res.data.userPosition
        }

        if (this.list.length >= res.data.total) {
          this.noMore = true
        }
      } else {
        this.$message.error("获取列表失败")
      }
    },

    async updateStatus(id, status, originStatus) {
      const statusText = ['取消', '正常', '普急', '中急', '特急'][status !== 1 ? status : originStatus]
      this.isLoading = true
      if (originStatus !== 1) status = 1;
      const res = await this.$api.Report.ProductionReport.updateStatus({progressId: id, status}).catch(() => {})
      this.isLoading = false
      if (res?.code === 200) {
        this.$message.success(`${originStatus !== 1 ? "取消" : ""}${statusText}成功`)
        this.form.pageSize = this.list.length
        await this.search()
        this.form.pageSize = this.$options.data().form.pageSize
      } else {
        this.$message.error(`${originStatus !== 1 ? "取消" : ""}${statusText}失败`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "production-report";
</style>
